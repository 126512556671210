import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link, useStaticQuery } from 'gatsby';

import CloudBgBottom from '../assets/images/cloud-bg-bottom.svg';
import { isAuthenticated } from '../utils/mmc-api/auth';

export const SIGNUP_CTA_SECTION_QUERY = graphql`
  query SignupCtaSectionQuery {
    datoCmsSection(name: { eq: "Call To Action" }) {
      description
      name
      image {
        alt
        url
      }
      subtitle
      title
      ctaSet {
        slug
        label
        id
      }
      collapsibleBlock {
        description
        title
        id
      }
    }
  }
`;

const SignupCtaSection = () => {
  const data = useStaticQuery(SIGNUP_CTA_SECTION_QUERY);
  const { image, subtitle, title } = data.datoCmsSection;

  return (
    <div id="CTA" className="cta-section" style={{ backgroundImage: `url(${image.url})` }}>
      <div className="div-block-2">
        <img src={CloudBgBottom} height="60" alt="Clouds Background" className="image" />
      </div>
      <div className="div-block-2 bottom">
        <img src={CloudBgBottom} height="60" alt="Clouds Background" className="image" />
      </div>
      <div className="container">
        <div className="title-wrap-centre">
          <h4>{title}</h4>
          <ReactMarkdown className="text-plain" children={subtitle} />
          {isAuthenticated() ? (
            <Link to="/modules" className="button w-button">
              Explore modules
            </Link>
          ) : (
            <Link to="/contact" className="button w-button">
              Contact us
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export { SignupCtaSection };
