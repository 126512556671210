import React, { useState } from "react";
import ReactMarkdown from 'react-markdown';
import { navigate } from "gatsby";
import rehypeRaw from 'rehype-raw';

import { useDispatch } from "react-redux";
import { hide } from '../store/sub_modal';

import CloseIcon from '../assets/icons/close.svg';

export default function SubscriptionModal({ data }) {
  const dispatch = useDispatch();

  const closeModal = () => {
    localStorage.setItem('subscription_modal_closed', new Date().toISOString().substring(0, 10));
    dispatch(hide());
  };

  const backgroundStyle = data?.backgroundUrl ? {
    backgroundImage: `url(${data.backgroundUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  } : {};


  const buttons = data.buttons ?? [];

  const goToSubscriptions = () => {
    navigate('/subscriptions');
    closeModal();
  };

  return (
    <div className="modalWrapper">
      <div className="wideModal scrollable" style={backgroundStyle}>
        <img className="modal-close-button" src={CloseIcon} alt="Close button for modal" onClick={closeModal} />
        <div className="text-center pb-1">
          <h2>Join MyMathsCloud</h2>
        </div>
        <ReactMarkdown className="paragraph mb-1" rehypePlugins={[rehypeRaw]} children={data?.content || ''} />
        {buttons?.length && (
          <div className="samples-container mb-1">
            {buttons.map(({ label, link }, i) => (
              <a
                href={link}
                key={`pdflink-${i}`}
                className="button w-button"
                target="_blank"
              >
                {label}
              </a>
            ))}
          </div>
        )}
        <div className="modalFooter">
          <button onClick={closeModal}>
            Close
          </button>
          <button
            className="button w-button"
            key={data?.callToActionText}
            onClick={goToSubscriptions}
          >
            {data?.callToActionText || 'Join Now'}
          </button>
        </div>
      </div>
    </div>
  );
}
