import React from 'react';
import ReactMarkdown from 'react-markdown';

import CloudBgBottom from '../assets/images/cloud-bg-bottom.svg';

const HookSection = ({ data: { description, description2, images, subtitle, title } }) => (
  <div className="pricing-section">
    <div className="math-pattern" style={{ backgroundImage: `url(${images[0].url})` }} />
    <div className="pattern-cover" />
    <div className="div-block-2">
      <img src={CloudBgBottom} height="60" alt="Clouds Background" className="image" />
    </div>
    <div className="container">
      <div className="title-wrap">
        <h2>{title}</h2>
        <p className="paragraph">
          {subtitle}
          <br />‍
        </p>
        {description && <ReactMarkdown className="paragraph who-is-this-for-list" children={description} />}
        {!description && description2 && (
            <div className="paragraph who-is-this-for-list" dangerouslySetInnerHTML={{ __html: description2 }} />
        )}
      </div>
      {/*<div className="w-layout-grid pricing">
        <div className="pricing-card">
          <div className="type-wrap">
            <h4>Regular</h4>
            <div className="icon-block">
              <img src="images/Price-Icon-01.svg" width="68" alt="" />
            </div>
          </div>
          <div className="pricing-wrap">
            <h3 className="pricing-h3">
              $25.99<span className="month-span">/month</span>
            </h3>
            <div className="pricing-strike">$50.99</div>
          </div>
          <p className="paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit suspendisse
            varius.
          </p>
          <div className="check-holder">
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>1 Account</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Global Usage</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Analytics Dashboard</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Accounts Payable</div>
            </div>
          </div>
          <a href="#CTA" className="button button-full w-button">
            Sign up for a Free Trial
          </a>
        </div>
        <div
          id="w-node-551c0d32c904-f8541bc3"
          className="pricing-card pricing-shadow"
        >
          <div className="type-wrap">
            <h4>Popular</h4>
            <div className="icon-block">
              <img src="images/Price-Icon-02.svg" alt="" />
            </div>
          </div>
          <div className="pricing-wrap">
            <h3 className="pricing-h3">
              $44.99<span className="month-span">/month</span>
            </h3>
            <div className="pricing-strike">$50.99</div>
          </div>
          <p className="paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit suspendisse
            varius.
          </p>
          <div className="check-holder">
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>3 Accounts</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Global Usage</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Analytics Dashboard</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Accounts Payable</div>
            </div>
          </div>
          <a href="#CTA" className="button button-full w-button">
            Sign up for a Free Trial
          </a>
        </div>
        <div className="pricing-card">
          <div className="type-wrap">
            <h4>Enterprise</h4>
            <div className="icon-block">
              <img src="images/Price-Icon-03.svg" alt="" />
            </div>
          </div>
          <div className="pricing-wrap">
            <h3 className="pricing-h3">
              $125.99<span className="month-span">/month</span>
            </h3>
            <div className="pricing-strike">$50.99</div>
          </div>
          <p className="paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit suspendisse
            varius.
          </p>
          <div className="check-holder">
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Unlimted Accounts</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Global Usage</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Analytics Dashboard</div>
            </div>
            <div className="check-wrap">
              <img src="images/check.svg" alt="" className="check" />
              <div>Accounts Payable</div>
            </div>
          </div>
          <a href="#CTA" className="button button-full w-button">
            Sign up for a Free Trial
          </a>
        </div>
</div>*/}
    </div>
  </div>
);

export { HookSection };
