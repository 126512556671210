import React from 'react';

import { CollapsibleBlock } from '../components';

const FAQsSection = ({ data: { collapsibleBlock, title } }) => (
  <div id="FAQ">
    <div className="container">
      <div className="w-layout-grid faq-grid">
        {collapsibleBlock.map((block) => (
          <CollapsibleBlock data={block} key={block.title} />
        ))}
      </div>
    </div>
  </div>
);

export { FAQsSection };
