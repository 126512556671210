import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import VideoPlayer from '../components/VideoPlayer';

const tutorfairReviewsQuery = graphql`
	query FETCH_TUTORFAIR_REVIEWS_QUERY {
		allDatoCmsTutorfairReview(sort: { fields: reviewSortOrder, order: ASC }) {
			edges {
				node {
					shortDescription
					reviewer
					originalId
					longComment
					reviewSortOrder
				}
			}
		}
	}
`;

const TestimonialsSection = ({ data }) => {
  const {
    allDatoCmsTutorfairReview: { edges },
  } = useStaticQuery(tutorfairReviewsQuery);

  const renderTestimonial = (testimonial) => {
    return (
      <div key={testimonial.name}>
        <h3>{testimonial.name}</h3>
        <VideoPlayer
          src={testimonial.images[0].url}
          height="350px"
          width="100%"
        />
      </div>
    );
  };
  return (
    <>
      <div className="testimonials">
        {data.map((testimonial) => renderTestimonial(testimonial))}
      </div>
      <div className="mt-5">
        {edges?.map(({ node: review }) => (
          <div className="flex flex-col text-center mb-2">
            <div style={{ width: '100%' }}>
              <div
                className="tutorfair-open-quote"
                style={{
                  textAlign: 'initial',
                  paddingLeft: '30px',
                  position: 'relative',
                }}
              >
                <svg
                  height="25px"
                  className="mx-auto mb-1"
                  fill="#23d4ff"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                >
                  <g>
                    <g id="right_x5F_quote">
                      <g>
                        <path d="M12 4v12h-8c0 4.41 3.586 8 8 8v4c-6.617 0-12-5.383-12-12V4H12z" />
                        <path d="M32 4v12h-8c0 4.41 3.586 8 8 8v4c-6.617 0-12-5.383-12-12V4H32z" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              {review.shortDescription ? (
                <h3 class="italic">{review.shortDescription}</h3>
              ) : (
                <div className="mt-2 tutorfair-open-quote-spacer"></div>
              )}
              <p
                className="text-base leading-6 italic"
                // style={{ maxWidth: '75%', margin: 'auto' }}
                dangerouslySetInnerHTML={{ __html: review.longComment }}
              ></p>
              <div
                style={{
                  textAlign: 'end',
                  paddingRight: '30px',
                  position: 'relative',
                  top: '-10px',
                }}
              >
                <svg
                  height="25px"
                  className="mx-auto mb-1"
                  fill="#23d4ff"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                >
                  <g id="right_x5F_quote">
                    <g>
                      <path d="M0,4v12h8c0,4.41-3.586,8-8,8v4c6.617,0,12-5.383,12-12V4H0z" />
                      <path d="M20,4v12h8c0,4.41-3.586,8-8,8v4c6.617,0,12-5.383,12-12V4H20z" />
                    </g>
                  </g>
                </svg>
              </div>
              <p
                className="sm:text-5xl font-bold"
                style={{ color: '#23d4ff', position: 'relative', top: '-20px' }}
              >
                {review.reviewer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { TestimonialsSection };
