import React, { useEffect } from 'react';
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from './../assets/icons/whatsapp.svg';

const Messenger = () => {
  // useEffect(() => {
  //     const chatbox = document.getElementById('fb-customer-chat');
  //     chatbox.setAttribute("page_id", "107565901566299");
  //     chatbox.setAttribute("attribution", "biz_inbox");

  //     window.fbAsyncInit = function() {
  //         FB.init({
  //             xfbml            : true,
  //             version          : 'v12.0'
  //         });
  //     };

  //     (function(d, s, id) {
  //         let js, fjs = d.getElementsByTagName(s)[0];
  //         if (d.getElementById(id)) return;
  //         js = d.createElement(s); js.id = id;
  //         js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
  //         fjs.parentNode.insertBefore(js, fjs);
  //     }(document, 'script', 'facebook-jssdk'));
  // });

  return (
    <a
      className="whatsapp-floating-button"
      href={`https://wa.me/447704004444?text=${encodeURIComponent(
        'Hi there, I am getting in touch via MyMathsCloud.com',
      )}`}
    >
      <img src={WhatsAppIcon} />
    </a>
    //   <div id="fb-root"></div>
    //   <div id="fb-customer-chat" className="fb-customerchat"></div>
  );
};

export { Messenger };
