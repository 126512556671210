import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import LockSubIcon from './../assets/icons/sub-lock.svg';
import UnlockSubIcon from './../assets/icons/open-sub-lock.svg';
import { useSelector } from 'react-redux';

const items = [
  {
    img: '/yoshi-running.gif',
    text: 'Co-ordinates',
    to: '/interactive-learning/co-ordinates',
  },
  {
    img: '/skigirl.png',
    text: 'Slope/Gradient',
    to: '/interactive-learning/slope-sister',
  },
];

export const INTERACTIVE_LEARNING_QUERY = graphql`
  query InteractiveLearningQuery {
    allDatoCmsInteractiveLearningPage(
      filter: { slug: { nin: ["/slope-sister", "/co-ordinates"] } }
      sort: { order: DESC, fields: order }
    ) {
      edges {
        node {
          title
          slug
          subscriptionOnly
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export function InteractiveLearningSection() {
  /**
   * @type {{
   *    allDatoCmsInteractiveLearningPage: {
   *      edges: {
   *        node: {
   *          title: String
   *          slug: String
   *          subscriptionOnly: boolean
   *          thumbnail?: {
   *            url: String
   *          }
   *        }
   *      }[]
   *    }
   * }}
   */
  const data = useStaticQuery(INTERACTIVE_LEARNING_QUERY);

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const goToInteractiveLearningPage = (slug, subscriptionOnly = false) => {
    if (!subscriptionOnly) return `/interactive-learning${slug}`;
    return isSubscribed ? `/interactive-learning${slug}` : '/subscriptions';
  };

  return (
    <div className="interactive-learning">
      <h3>Exercises</h3>
      <div className="brainteasers-container">
        {items.map(({ img, text, to }, i) => (
          <Link to={to} key={i} className="brainteaser-grid-item">
            <div className="image-container">{img && <img src={img} />}</div>
            <div className="title-container">
              <p>{text}</p>
            </div>
          </Link>
        ))}
        {data.allDatoCmsInteractiveLearningPage.edges.map(
          ({ node: { title, slug, thumbnail, subscriptionOnly } }) => (
            <Link
              to={goToInteractiveLearningPage(slug, subscriptionOnly)}
              key={slug}
              className="brainteaser-grid-item"
            >
              <div className="image-container">{thumbnail && <img src={thumbnail.url} />}</div>
              <div className="title-container">
                <p>{title}</p>
                {subscriptionOnly && <img src={isSubscribed ? UnlockSubIcon : LockSubIcon} />}
              </div>
            </Link>
          ),
        )}
      </div>
      <div className="grid"></div>
    </div>
  );
}
