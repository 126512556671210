import React from 'react';
import ReactMarkdown from 'react-markdown';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const AboutSection = ({ data: { description, description2, images, title } }) => {
  return (
    <div className="container">
      <br />
      <br />
      <div className="w-layout-grid content-grid author">
        <div className="image-wrapper">
          <Carousel>
            {images.map((image) => (
              <div key={image.url}>
                <img src={image.url} alt="About" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="content-block flex-start">
          <h2 className="content-h2">{title}</h2>
            {description && <ReactMarkdown className="hero-paragraph" children={description} />}
            {!description && description2 && (
                <div className="hero-paragraph" dangerouslySetInnerHTML={{ __html: description2 }} />
            )}
        </div>
      </div>
    </div>
  );
};

export { AboutSection };
