import React from 'react';
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaVolumeRange,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaFullscreenButton,
} from 'media-chrome/dist/react';
import 'hls-video-element';
import 'youtube-video-element';

export default function VideoPlayer({ src, isSmall = false, className, height, width = '100%' }) {
  const isHLS = src.endsWith('.m3u8');
  const isYoutube = src.includes('youtube.com');

  const handleClick = (e) => {
    if (!isYoutube) return;
    e.stopPropagation();
    window.open(src, '_blank').focus();
  };

  return (
    <div
      className={className}
      onClick={handleClick}
      style={{ cursor: isYoutube ? 'pointer' : 'default' }}
    >
      <MediaController
        onClick={handleClick}
        style={{ height, width, aspectRatio: '16/9', pointerEvents: isYoutube ? 'none' : 'auto' }}
      >
        {isYoutube ? (
          <youtube-video slot="media" src={src} crossorigin reload="auto" />
        ) : isHLS ? (
          <hls-video slot="media" src={src} preload="metadata" crossOrigin="" />
        ) : (
          <video slot="media" src={src} preload="metadata" crossOrigin="" />
        )}
        {!isYoutube && (
          <MediaControlBar>
            <MediaPlayButton></MediaPlayButton>
            {!isSmall && (
              <>
                <MediaTimeDisplay showDuration></MediaTimeDisplay>
                <MediaSeekBackwardButton></MediaSeekBackwardButton>
                <MediaSeekForwardButton></MediaSeekForwardButton>
              </>
            )}
            <MediaTimeRange></MediaTimeRange>
            <MediaMuteButton></MediaMuteButton>
            <MediaVolumeRange></MediaVolumeRange>
            <MediaFullscreenButton></MediaFullscreenButton>
          </MediaControlBar>
        )}
      </MediaController>
    </div>
  );
}
