import React, { useEffect, useState } from 'react';

import ChevronIcon from '../assets/icons/chevron.svg';

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (typeof window !== `undefined`) {
    window.addEventListener('scroll', checkScrollTop);
  }

  return (
    <button
      className="return-top w-inline-block"
      onClick={scrollTop}
      style={{ display: showScroll ? 'flex' : 'none' }}
    >
      <img src={ChevronIcon} alt="Chevron" />
    </button>
  );
};

export { ScrollToTop };
