import React from 'react';
import BuyMeACoffeeImage from '../../assets/images/bmac-btn.png';

const Secondary = ({ links }) => {
  const renderNav = (link) => (
    <a
      href={link.externalLink}
      className="navigation-link w-nav-link"
      key={link.label}
      style={{ fontSize: 12 }}
      target="_blank"
      rel="noreferrer"
    >
      {link.icon && link.icon.url && (
        <img src={link.icon.url} style={{ height: 12, marginRight: 5 }} alt="Link" />
      )}{' '}
      {link.label}
    </a>
  );
  return (
    <nav role="secondary navigation" className="nav-menu-2 w-nav-menu secondary-nav">
      {/* <img src={BuyMeACoffeeImage} onClick={() => window.open('https://www.buymeacoffee.com/mymathscloud', '_blank')} className="cursor-pointer bmac-btn" /> */}
      {links.map(renderNav)}
    </nav>
  );
};

export { Secondary };
