import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'gatsby';

const TutorfairReviewCarousel = ({ data: { edges } }) => {
	const settings = {
		dots: true,
		infinite: true,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		appendDots: (dots) => (
			<div>
				<ul style={{ paddingLeft: '0px' }}> {dots} </ul>
			</div>
		),
		customPaging: (i) => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div className="slick-dot"></div>
			</div>
		),
		nextArrow: <></>,
		prevArrow: <></>,
	};

	return (
		<div className="container mt-6">
			<div className="text-center">
                {/* <p className="m-0 bold 1.5 text-center">Featured Documents</p> */}
				<h2 className="text-center font-extrabold">What our clients say</h2>
			</div>
			<div className="mt-20">
				<ul
					style={{ paddingLeft: '0px' }}
					className="md:grid md:grid-cols-3 md:col-gap-10 md:row-gap-10"
				>
					<Slider {...settings}>
						{edges.map(({ node }) => {
							return (
								<li className=" bg-gray-100 pl-2 pr-2 text-center">
									<div className="flex flex-col">
										<div style={{width : "100%" }}>
											<div 
                                                className='tutorfair-open-quote'
												style={{
													textAlign: 'initial',
													paddingLeft: '20px',
													position: 'relative',
												}}
											>
												<svg
													height="25px"
													className="mx-auto mb-1"
													fill="#23d4ff"
													version="1.1"
													id="Capa_1"
													x="0px"
													y="0px"
													viewBox="0 0 32 32"
												>
													<g>
														<g id="right_x5F_quote">
															<g>
																<path d="M12 4v12h-8c0 4.41 3.586 8 8 8v4c-6.617 0-12-5.383-12-12V4H12z" />
																<path d="M32 4v12h-8c0 4.41 3.586 8 8 8v4c-6.617 0-12-5.383-12-12V4H32z" />
															</g>
														</g>
													</g>
												</svg>
											</div>
											{node.shortDescription && (
												<h3 className="italic">{node.shortDescription}</h3>
											)}
											<p
												className="mb-1 text-base leading-6 italic"
												dangerouslySetInnerHTML={{ __html: node.longComment }}
											/>
											<div
												style={{
													textAlign: 'end',
													paddingRight: '30px',
													position: 'relative',
													top: '-10px',
												}}
											>
												<svg
													height="25px"
													className="mx-auto mb-1"
													fill="#23d4ff"
													version="1.1"
													id="Capa_1"
													x="0px"
													y="0px"
													viewBox="0 0 32 32"
												>
													<g id="right_x5F_quote">
														<g>
															<path d="M0,4v12h8c0,4.41-3.586,8-8,8v4c6.617,0,12-5.383,12-12V4H0z" />
															<path d="M20,4v12h8c0,4.41-3.586,8-8,8v4c6.617,0,12-5.383,12-12V4H20z" />
														</g>
													</g>
												</svg>
											</div>
											<p
												className="sm:text-5xl font-bold"
												style={{ color: '#23d4ff', position: 'relative', top: '-20px' }}
											>
												{node.reviewer}
											</p>
										</div>
									</div>
								</li>
							);
						})}
					</Slider>
					<div className="mb-2"></div>
					<Link to={`/testimonials`}>
						<p
							className="text-center"
							style={{
								color: '#fd99c5',
								fontSize: '11px',
								textDecoration: 'inherit',
							}}
						>
							View all Testimonials
						</p>
					</Link>
				</ul>
			</div>
		</div>
	);
};

export { TutorfairReviewCarousel };
